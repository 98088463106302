<template>
  <v-card class="mx-auto">
    <v-app-bar dark color="primary">
      <v-icon large class="mr-3">notifications_paused</v-icon>
      <v-toolbar-title>Notify Task</v-toolbar-title>
    </v-app-bar>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col>
            <v-select
              label="Select Task"
              :items="tasks"
              item-text="name.en"
              item-value="_id"
              outlined
              dense
              v-model="dataToSend.taskId"
              required
              :rules="[(v) => (!!v && v.length > 0) || 'Task is required']"
            />
          </v-col>
          <v-col>
            <v-select
              label="Select Status"
              :items="[
                { key: 'A', label: 'Active' },
                { key: 'C', label: 'Complete' },
              ]"
              item-text="label"
              item-value="key"
              outlined
              dense
              v-model="dataToSend.status"
              :disabled="!dataToSend.taskId"
              required
              :rules="[(v) => (!!v && v.length > 0) || 'Status is required']"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="dataToSend.title"
              label="Notification Title"
              outlined
              dense
              full-width
              required
              :rules="[(v) => (!!v && v.length > 0) || 'Title is required']"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="dataToSend.body"
              label="Notification Body"
              outlined
              dense
              full-width
              required
              :rules="[(v) => (!!v && v.length > 0) || 'Body Name is required']"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn color="primary" class="mt-3" @click="notifyRespondents">
              Send Notification
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";

const service = new RestResource();

export default {
  data() {
    return {
      tasks: [],
      dataToSend: {
        taskId: undefined,
        status: undefined,
        title: "",
        body: "",
      },
    };
  },

  mounted() {
    this.getTasks();
  },

  methods: {
    getTasks() {
      service.getTasks().then((r) => {
        this.tasks = r.data;
      });
    },
    notifyRespondents() {
			if (this.$refs.form.validate()) {
        this.$setLoader();
        if (this.dataToSend.taskId && this.dataToSend.status) {
          service.notifyTaskCompletion(this.dataToSend).then((res) => {
            if (res) {
              this.dataToSend.title = "";
              this.dataToSend.body = "";
              this.$disableLoader();
            }
          });
        } else {
          console.error("Please select both Task and Status.");
        }
      }
    },

    validateTitle() {
      const title = this.dataToSend.title;
      return title ? true : "Title is required";
    },

    validateBody() {
      const body = this.dataToSend.body;
      return body ? true : "Body is required";
    },
  },
};
</script>
